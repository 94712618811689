import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DeliveryOrderService } from "app/main/all-orders/delivery-order/delivery-order.service";

@Component({
    selector: "app-select-driver-bulk-order",
    templateUrl: "./select-driver-bulk-order.component.html",
    styleUrls: ["./select-driver-bulk-order.component.scss"],
})
export class SelectDriverBulkOrderComponent implements OnInit {
    public confirmMessage: string;
    public dialogHeading: string = "Select Driver";
    allDrivers = [];
    disableFieldColorInfo:any = 'white';

    merchantFilterForm: FormGroup;


    orderVehicleType = 2;
    /**
     * Constructor
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public _dialogRef: MatDialogRef<SelectDriverBulkOrderComponent>,
        private _DeliveryOrderService: DeliveryOrderService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {

      this.merchantFilterForm = this.createMerchantFilterForm();

    }

    ngOnInit(): void {}

    /**
         * Creates Location filter form
         * 
         * @returns {FormGroup}
         */

    createMerchantFilterForm(): FormGroup
    {
        return new FormGroup({
            driverId  : new FormControl(),
            assign_driver  : new FormControl(),
        });
    }




    getRouteDriver() {
        this.merchantFilterForm.markAsPending(); 
        this.allDrivers = [];
        var toSend = {
            vehicleType: "2",
        };
        this._DeliveryOrderService.getRouteDriver(toSend).then((res) => {
            console.log("this.allDrivers", this.allDrivers);
            this.allDrivers = res;
        });
    }


    onChangeDriver(event){

      // this.isShowFetchText = true;
      // if(event.value==''){
      //     this.isShowFetchText = false;
      // }

  }
}
