export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'MAIN MENU': 'Main Menu',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            }
        }
    }
};
