import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class DeliveryOrderService implements Resolve<any>
{
    routeParams: any;
    order: any;
    onOrderChanged: BehaviorSubject<any>;
    token:any;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults

        this.onOrderChanged = new BehaviorSubject({});

    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getOrder()
            ]).then(
                () => {
                    resolve('');
                },
                (error) => {
                    console.log('Error in navigation :', error);
                    alert('Sorry!! Cannot navigate to Order at the moment !! ');
                    reject();
                }
            );
        });
    }

    /**
     * Get order
     *
     * @returns {Promise<any>}
     */
    getOrder(): Promise<any>
    {
          var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            'x-access-token': localStorage.getItem('userToken')
          });

        return new Promise((resolve, reject) => {
            //TODO: change Fake call with ID(api)
            // this._httpClient.get(environment.apiURL +'api/merchant/order/details/' + this.routeParams.id ,  {headers:reqHeaders})
            this._httpClient.get(environment.apiURL +'api/v12/admin/get-delivery-orderV2/' + this.routeParams.orderId ,  {headers:reqHeaders})
                .subscribe((response: any) => {
            // TODO: ORIGINAL LOGIC TO FIND ID
                    console.log('type :', this.routeParams.id);
                    this.order = response.data[0];
                    console.log('Recieved Response =>',response);
                    console.log('Recieved (Particular) Order =>',this.order);

                    this.onOrderChanged.next(this.order);
                    resolve(response);
                }, reject);
        });

    }

    /**
     * check wallet balance
     *
     * @returns {Promise<any>}
     */

    checkWalletAmount(toSend){

        const reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
        }); 

        const url = 'api/v12/admin/check-driver-wallet';
        
        return new Promise((resolve, reject) => {
            //TODO: change Fake call with ID(api)
            // this._httpClient.get(environment.apiURL +'api/merchant/order/details/' + this.routeParams.id ,  {headers:reqHeaders})
            this._httpClient.post(environment.apiURL + url , toSend ,  {headers:reqHeaders})
                .subscribe((response: any) => {
                    // this.order = response.data[0];
                    console.log('Recieved Response =>',response);
                    resolve(response);
                }, reject);
        });
    }



    /**
     * Get reRouteOrder to driver
     *
     * @returns {Promise<any>}
     */
    reRouteOrder(toSend): Promise<any>
    {
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
          });

        //  let url = 'api/v11/admin/update-delivery-order';

        //  if(this.order.deliveryStatus == 3  || this.order.deliveryStatus == 4){
             let url = 'api/v12/admin/update-accepted-delivery-order';
        //  }

        toSend.driverUserId = toSend.driverId;

        return new Promise((resolve, reject) => {
            //TODO: change Fake call with ID(api)
            // this._httpClient.get(environment.apiURL +'api/merchant/order/details/' + this.routeParams.id ,  {headers:reqHeaders})
            this._httpClient.post(environment.apiURL + url , toSend ,  {headers:reqHeaders})
                .subscribe((response: any) => {
            // TODO: ORIGINAL LOGIC TO FIND ID
                    console.log('type :', this.routeParams.id);
                    // this.order = response.data[0];
                    console.log('Recieved Response =>',response);
                    // console.log('Recieved (Particular) Order =>',this.order);
                    // this.onOrderChanged.next(this.order);
                    resolve(response);
                }, reject);
        });
    }



     /*** reassinged Driver *///
     reassignedOrder(toSend): Promise<any>
    {
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
        });

        let url = 'api/v12/admin/update-accepted-delivery-order';
        
        toSend.driverUserId = toSend.driverId;

        return new Promise((resolve, reject) => {
            //TODO: change Fake call with ID(api)
            // this._httpClient.get(environment.apiURL +'api/merchant/order/details/' + this.routeParams.id ,  {headers:reqHeaders})
            this._httpClient.post(environment.apiURL + url , toSend ,  {headers:reqHeaders})
                .subscribe((response: any) => {
                    // TODO: ORIGINAL LOGIC TO FIND ID
                    console.log('type :', this.routeParams.id);
                    // this.order = response.data[0];
                    console.log('Recieved Response =>',response);
                    // console.log('Recieved (Particular) Order =>',this.order);
                    // this.onOrderChanged.next(this.order);
                    resolve(response);
                }, reject);
        });
    }


    cancelOrder(toSend): Promise<any>
    {
        //   TODO: take token from local storage 
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
          });

        return new Promise((resolve, reject) => {
           
            
            this._httpClient.post(environment.apiURL + 'api/v12/admin/cancel-order' , toSend,  {headers:reqHeaders})
                .subscribe((response: any) => {
           
                    resolve(response);
                }, reject);
        });

    }
    



    completeOrder(toSend): Promise<any>
    {
        //   TODO: take token from local storage 
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
          });

        return new Promise((resolve, reject) => {
           
            
            this._httpClient.post(environment.apiURL + 'api/v12/admin/complete-PdasOrder' , toSend,  {headers:reqHeaders})
                .subscribe((response: any) => {
           
                    resolve(response);
                }, reject);
        });

    }


    getRouteDriver(toSend, getCountry?): Promise<any>
    {
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
        });

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL +'api/v12/admin/get-drivers', toSend ,  {headers:reqHeaders})
                .subscribe((response: any) => {
                     console.log(this.order);
                   // let locationString = JSON.parse(this.order.location_string);
                   if(getCountry){
                    if(getCountry=='Ghana'){
                        response =  response.data.ghdrivers;
                        console.log("response in gn", response);
                      } else {
                        response =  response.data.ngdrivers;
                        console.log("response in ng", response);
                    }
                   }else{
                       let country = this.order.country; 
                       if(country=='Ghana'){
                           response =  response.data.ghdrivers;
                         } else {
                           response =  response.data.ngdrivers;
                       }
                           
                   }
                    resolve(response);
                }, reject);
        });
    }

    getNearByDrivers(toSend, getCountry?): Promise<any>
    {
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
        });

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL +'api/v12/admin/get-nearby-drivers', toSend ,  {headers:reqHeaders})
                .subscribe((response: any) => {
                    //  console.log(this.order);
                    console.log("res=-=-=-=-===--=->>", response);
                    
                   // let locationString = JSON.parse(this.order.location_string);
                //    if(getCountry){
                //     if(getCountry=='Ghana'){
                //         response =  response.data.ghdrivers;
                //         console.log("response in gn", response);
                //       } else {
                //         response =  response.data.ngdrivers;
                //         console.log("response in ng", response);
                //     }
                //    }else{
                //        let country = this.order.country; 
                //        if(country=='Ghana'){
                //            response =  response.data.ghdrivers;
                //          } else {
                //            response =  response.data.ngdrivers;
                //        }
                           
                //    }
                    resolve(response);
                }, reject);
        });
    }



    addUpdatePdasPrefferedDriver(data){
        let reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
        });
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL +'api/v12/admin/addUpdatePdasPreferredDriver', data ,  {headers:reqHeaders})
            .subscribe((response: any) => {
                resolve(response);
            }, reject)
        })
    }

    checkDriverBalance(data){
        let reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
        });
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL +'api/v12/admin/checkDriverBalance', data ,  {headers:reqHeaders})
            .subscribe((response: any) => {
                resolve(response);
            }, reject)
        })
    }

   


    // Methods for Future sections
    // 
    //


    /**
     * Save order
     *
     * @param order
     * @returns {Promise<any>}
     */
    // saveOrder(order): Promise<any>
    // {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post('api/e-commerce-orders/' + order.id, order)
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    /**
     * Add order
     *
     * @param order
     * @returns {Promise<any>}
     */
    // addOrder(order): Promise<any>
    // {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post('api/e-commerce-orders/', order)
    //             .subscribe((response: any) => {
    //                 resolve(response);
    //             }, reject);
    //     });
    // }



    changeDropLocation(toSend): Promise<any>
    {
        console.log("toSend====>", toSend);
        //   TODO: take token from local storage 
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
          });

        return new Promise((resolve, reject) => {
           
            
            this._httpClient.post(environment.apiURL + 'api/v12/admin/updateOrderDropLocation' , toSend,  {headers:reqHeaders})
                .subscribe((response: any) => {
           
                    resolve(response);
                }, reject);
        });

    }


    addBonusForOrder(toSend): Promise<any>
    {
        //   TODO: take token from local storage 
        var reqHeaders = new HttpHeaders({
            'Content-Type' : 'application/json',
            'x-access-token': localStorage.getItem('userToken')
          });

        return new Promise((resolve, reject) => {
           
            
            this._httpClient.post(environment.apiURL + 'api/v11/admin/addDriverBonus' , toSend,  {headers:reqHeaders})
                .subscribe((response: any) => {
           
                    resolve(response);
                }, reject);
        });

    }
}
