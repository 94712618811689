<!-- <h1 matDialogTitle></h1> -->

<div class="dialog-content-wrapper">
    <mat-toolbar class="primary-700 m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" style="color: #399ce5">{{tutTitle}}</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>


    <div mat-dialog-content class="p-24 pt-0 m-0 primary-700">
        <!-- <div class="desc"    fxLayout="column"> -->
        <!-- <span class="title dialog-title">{{tutDesc}}</span> -->


        <div class="content">
            <div class="cropper-section m-8">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio"
                    [aspectRatio]="this.toNumber(aspectRatio)" [cropperMinWidth]="128" format="png" [onlyScaleDown]="false"
                    [transform]="transform" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
                    [style.display]="showCropper ? null : 'none'" [alignImage]="'center'">
                </image-cropper>

            </div>
 
            <div class="text-center crop-image-view cropper-preview m-8" fxHide.xs>
                <img class="viewerWindow" [src]="croppedPreview"/>
            </div>

        </div>

        <!--                 <mat-slider min="1" max="5" step="0.5" value="1.5"></mat-slider>-->

        <!-- <div class="p-5 mat_slider">
            <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="1" min="10" max="100" step="10" value="10"
                color="accent" (change)="changeSliderPointer($event)"></mat-slider>
        </div> -->

        <!--                    <mat-slider min="1" max="5" step="0.5" value="1.5" type="range"></mat-slider>-->
        <!--                <div  fxLayout="row">-->

        <!--                    <button   mat-icon-button class="mat-accent mr-16"  (click)="zoomIn()">-->
        <!--                        <mat-icon class="secondary-text">zoom_in</mat-icon>-->
        <!--                    </button>-->

        <!--                    <button   mat-icon-button class="mat-accent mr-16" (click)="zoomOut()">-->
        <!--                        <mat-icon class="secondary-text">zoom_out</mat-icon>-->
        <!--                    </button>-->


        <!--                </div>-->

    </div>



</div>








<!-- <div mat-dialog-content></div> -->
<div mat-dialog-actions class="m-0 p-16 primary-600" fxLayout="row" fxLayoutAlign="flex-end center">
    <button mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(['save', croppedImage])">Save</button>
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
</div>