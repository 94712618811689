import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { DecoderPipe } from './decoder.pipe';
import { FirstImgComaSepStringPipe } from './first.img-coma-sep-img.pipe';
import { AbsPipe } from './abs.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DecoderPipe,
        HtmlToPlaintextPipe,
        FirstImgComaSepStringPipe,
        AbsPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DecoderPipe,
        FirstImgComaSepStringPipe,
        AbsPipe
    ]
})
export class FusePipesModule
{
}
