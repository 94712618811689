import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-convenience-fee-dialog',
  templateUrl: './convenience-fee-dialog.component.html',
  styleUrls: ['./convenience-fee-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ConvenienceFeeDialogComponent implements OnInit {

  form : FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb : FormBuilder,
    public _matDialogRef: MatDialogRef<ConvenienceFeeDialogComponent>
  ) { 
    console.log("_data", _data);
    this.createForm(_data.pdasInfo.convenienceFeePercent);
  }

  
  ngOnInit(): void {
  }


  createForm(feeValue){
    return this.form = this._fb.group({
      fee : [feeValue ?? '', [Validators.required,Validators.max(100)]]
    })
  }

  onSubmit(){
    const payload = {
      "id" : this._data.pdasInfo?.userId,
      "convenienceFeePercent" :  Number(this.form?.value?.fee)
    }
    this._matDialogRef.close(payload);
  }
}
