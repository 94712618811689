import { Component, Inject, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector     : 'remark-dialog',
    templateUrl  : './remark-dialog.component.html',
    styleUrls    : ['./remark-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RemarkDialogComponent implements OnInit,OnDestroy
{
    showLocationFilterFields: boolean;
    showDateFilterFields: boolean;
    orderCancelForm: FormGroup;
  

    /**
     * Constructor
     *
     * @param {MatDialogRef<RemarkDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<RemarkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        // private _merchantService: MerchantService,
        private _formBuilder: FormBuilder,

        

    )
    {

        this.orderCancelForm = this.createOrderCancelForm();
    }

    ngOnInit(){


    }

        /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

            /**
         * Creates order cancellation form
         * 
         * @returns {FormGroup}
         */

        createOrderCancelForm(): FormGroup
        {
            return this._formBuilder.group({
                cancellationReason:  ['',Validators.required]                
            });
           
        }

}
