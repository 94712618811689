import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-common-cropper',
  templateUrl: './common-cropper.component.html',
  styleUrls: ['./common-cropper.component.scss']
})
export class CommonCropperComponent implements OnInit {
  public confirmMessage: string;
  public tutTitle: string;
  public tutImage: string;
  public tutDesc: string;
  public tuts:any;
  imageChangedEvent: any = '';
  public croppedImage: any ;
  croppedPreview: any = '';
  scale = 1;
  showCropper: boolean = false;
  public transform: ImageTransform = {};
  public resolutionWidth: any ;
  public resolutionHeight: any ;
  previousValue:number = 1;
  aspectRatio;
  maintainAspectRatio:boolean = true;

   /**
     * Constructor
     *
     * @param {MatDialogRef<CommonCropperComponent>} dialogRef
     */
  constructor(
    public dialogRef: MatDialogRef<CommonCropperComponent>
  ) { }

  ngOnInit(): void {
    console.log("dialogRef", this.dialogRef.componentInstance);
    console.log("aspectRatio", this.aspectRatio);
    
    
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log("OUTPUT: imageChangedEvent", event) 
  }

  
  imageCropped(event: ImageCroppedEvent) {
    this.croppedPreview = event.base64;
    this.croppedImage = event;
    this.croppedImage.file = base64ToFile(event.base64);
    this.resolutionWidth = event.width;
    this.resolutionHeight = event.height;
    // console.log("OUTPUT: imageCropped -> event", event)
  }

  imageLoaded() {
    // show cropper
    this.showCropper = true;

  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  formatLabel(value: number) {
    return value  + '%';
  }

  changeSliderPointer(event){

    let newValue = event.value/10;


   //    this.previousValue = newValue
   console.log("OUTPUT ~ file: cropper-dialog.component.ts ~ line 72 ~ changeSliderPointer ~ event.value", event.value);
   console.log("OUTPUT ~ file: cropper-dialog.component.ts ~ line 68 ~ changeSliderPointer ~ zoomDirection", newValue);

   //    if(this.previousValue < event.value)
   //    {
   //     this.zoomOut(newValue)
   //    }
   //    else
   //    {
   //     this.zoomIn(newValue)
   //    }

       if(event.value == 10)
       {
           this.scale = 1;
           this.changeZoomLevel(this.scale);
           this.previousValue = event.value;

       }
       else if( this.previousValue > event.value)
       {
           this.scale -= newValue;
           // to avid rotation of image
           this.scale = (Math.sign(this.scale) == -1) ? this.scale *= -1 : this.scale;
           this.changeZoomLevel(this.scale);
           this.previousValue = event.value;
           console.log("Zoom OUT",{"previousValue": this.previousValue, "newValue": newValue, "Scale": this.scale, "Event": event.value});

       }
       else if(this.previousValue < event.value)
       {

           let newWidth = this.resolutionWidth * newValue;
           let newHeight = this.resolutionHeight * newValue;

           // if(newValue > 3)
           // {
           //     alert('Too zoom');
           //     return false;
           // }

           this.scale += newValue;
           this.changeZoomLevel(this.scale);
           console.log("Zoom IN",{"previousValue": this.previousValue, "newValue": newValue, "Scale": this.scale, "Event": event.value});
           this.previousValue = event.value;

       }

    
   }


   changeZoomLevel(scale) {
       
      this.transform = {
          ...this.transform,
          scale: scale
      };
      
    }

    toNumber(value){
      return String(value);
    }


    zoomOut(newValue) {
      if(this.scale > 1)
      {
          // this.scale -= .1;
          this.scale *= newValue
          this.transform = {
              ...this.transform,
              scale: this.scale
          };
      }
    }

    zoomIn(newValue) {

      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };

      console.log('ZOOM IN',{"transform": this.transform, "Scale": this.scale});

    }


}
