import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@fuse/utils';

@Pipe({name: 'decoder'})
export class DecoderPipe implements PipeTransform
{
    /**
     * Decodes encoded strings
     *
     * @param {string} searchText
     * @returns {any}
     */
    transform(property: string): any
    {
        let decodedString = decodeURIComponent(property)
        return decodedString;
    }
}
