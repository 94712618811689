import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkDeliveryConfigComponent } from './bulk-delivery-config/bulk-delivery-config.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BulkDeliveryConfigService } from './bulk-delivery-config.service';

const routes: Routes = [
  {
      path     : 'config', pathMatch : 'full',
      component: BulkDeliveryConfigComponent,
      resolve:{
        versions: BulkDeliveryConfigService
      }, 
  }
];


@NgModule({
  declarations: [BulkDeliveryConfigComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FuseSharedModule,
    FuseSidebarModule,
    MatStepperModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule
  ]
})
export class BulkDeliveryConfigModule { }
