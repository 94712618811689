import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absPipe'
})

// @Pipe({name: 'decoder'})
export class AbsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
        console.log("value in abs pipe ==>",value);
    
    if (value === '') {
      return '';
    }
    if(value){
        if(Math.sign(value))
            var myVal = Math.abs(value)

            myVal = Math.round(myVal)
        console.log("myVal after pipe Transform",myVal);
        
        return myVal;
      } 

      // console.log("first image  ",myArr[0]);
      }
    
}
