<div fxLayout="column" fxFlex fusePerfectScrollbar>

    <mat-list class="date" cdkFocusRegionStart>

        <h3 matSubheader cdkFocusInit>
            <span>Today</span>
        </h3>

        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12">
                {{date | date:'EEEE'}}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <span> {{date | date:'d'}}</span>
                <span class="mat-subheading-1">th</span>
                <span> {{date | date:'MMMM'}}</span>
            </div>
        </div>
    </mat-list>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <mat-list>
        <h3 matSubheader>
            <span>Events</span>
        </h3>
       
        <!-- <mat-list-item *ngFor="let event of events">
            <h3 matLine>{{event.title}}</h3>
            <p matLine class="secondary-text">{{event.detail}}</p>
        </mat-list-item> -->

        <mat-list-item>
                <h3 matLine>Event Name</h3>
                <p matLine class="secondary-text">Event detail from calendar</p>
        </mat-list-item>



    </mat-list>

    <mat-divider></mat-divider>

    


</div>