<div class="dialog-content-wrapper">
    <mat-toolbar class="primary-700 m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogHeading}}</span>
            <button mat-icon-button (click)="_dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div class="p-24">
    <form name="merchantFilterForm" [formGroup]="merchantFilterForm" class="compose-form" fxLayout="column" fxFlex>
        <mat-form-field appearance="outline" color="accent" floatLabel="always" class="w-100-p">
            <mat-label style="color: whitesmoke;">
                Driver -
                <span *ngIf="this.orderVehicleType == 1">Bycycle</span>
                <span *ngIf="this.orderVehicleType == 2">Motor Bike</span>
                <span *ngIf="this.orderVehicleType == 4">Van</span>
            </mat-label>
            <input [style.color]="disableFieldColorInfo" matInput
                   placeholder="Driver"
                   aria-label="Driver"
                   [matAutocomplete]="auto11"
                   formControlName="driverId"
                   required
                   (input)="onChangeDriver($event)"
            >
            <mat-autocomplete #auto11="matAutocomplete"  [style.color]="disableFieldColorInfo">
                <mat-option *ngFor="let driver of this.allDrivers" [value]="driver.id+' | '+driver.name" >
                    {{driver.id}} |  <span class="mr-4">{{driver.name}}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field> 
    </form>
</div>