import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-faq-dialog',
  templateUrl: './add-edit-faq-dialog.component.html',
  styleUrls: ['./add-edit-faq-dialog.component.scss']
})
export class AddEditFaqDialogComponent implements OnInit {

  form:FormGroup
  constructor(
    private _fb : FormBuilder,
    public dialogRef: MatDialogRef<AddEditFaqDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { 
    if(_data.faqData){
      this.createForm(_data.faqData);
    }else{
      this.createForm();
    }
  }

  ngOnInit(): void {
    
  }

  createForm(data?){
    this.form = this._fb.group({
      id :[data?.id || ''],
      question : [data?.question || '', Validators.required],
      answer : [data?.answer || '', Validators.required]
    })
  }

}
