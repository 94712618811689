import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-strictly-dialog',
  templateUrl: './strictly-dialog.component.html',
  styleUrls: ['./strictly-dialog.component.scss']
})
export class StrictlyDialogComponent implements OnInit {

  dontShowagain =  new FormControl(false);
   /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
  constructor(
    public dialogRef: MatDialogRef<StrictlyDialogComponent>,
  )
  { 
  }


  ngOnInit(): void {
  }

  onClose(){

  }

  onSubmit(){
    this.dialogRef.close(this.dontShowagain.value)
  }

}
