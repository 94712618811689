
export const shift = 3;

export function encrypt(text, shift) {
    let result = '';
    
    for (let i = 0; i < text.length; i++) {
      let char = text[i];
      
      // Check if the character is a letter
      if (char.match(/[a-zA-Z]/)) {
        let code = text.charCodeAt(i);
        
        // Shift uppercase letters
        if (char >= 'A' && char <= 'Z') {
          code = ((code - 65 + shift) % 26) + 65;
        }
        // Shift lowercase letters
        else if (char >= 'a' && char <= 'z') {
          code = ((code - 97 + shift) % 26) + 97;
        }
        
        result += String.fromCharCode(code);
      } else {
        // If not a letter, just add the character as it is
        result += char;
      }
    }
  
    return result;
}
 
// Function to decrypt a message using Caesar Cipher
export function decrypt(text, shift) {
    return encrypt(text, -shift);  // Decryption is just the reverse of encryption
}

export const accessDeniedLabel = "Access Denied";

export const permissionType = {
    action : "Read/Write",
    show : "Read",
    showHide : "Hide"
}

export const deliveryStatus = {
    0: 'Self Pickup',
    1:'Delivery Requested',
    2:'Ready For Shipment',
    3:'Driver Assigned',
    4:'Shipped',
    5:'Delivered',
    6:'Canceled'
}

export const filterByOrderStatus = {
    0 : 'ALL',
    1 : 'Cancelled',
    2 : 'Returned',
    3 : 'Delivered'
}

export const bulkOrderDeliveryStatus = {
    0 : 'Pending',
    1 : 'Confirm',
    2 : 'Order Created',
    3 : 'Delivered',
    4 : 'Cancel'
}

export const country = { 
    Ghana:'Ghana',
    Nigeria:'Nigeria',
}

export const paymentEnum = {
    cashOnPickup:  1,
    cashOnDelivery: 2,
    online: 3,
    Wallet: 4
}

// { id: 1, type: 1, title: 'Cash on Pickup', selected: Images.pickupActive, unselected: Images.cashPickup },
// { id: 2, type: 2, title: 'Cash on Delivery', selected: Images.codActive, unselected: Images.cod },
// { id: 3, type: 3, title: 'Online', selected: Images.ePayActive, unselected: Images.ePay },
// { id: 4, type: 3, title: 'Wallet', selected: Images.select_wallet_ic, unselected: Images.unselect_wallet_ic },

export const timeFilterEnums = {
    1 : 'Daily',
    2 : 'Weekly',
    3 : 'Monthly',
    4 : 'Yearly'
}

/* 
    Bulk Enums for Notify Type PDAS
*/
export const bulkFilter = {
    0 : 'All',
    1 : 'Bulk User',
}

export const bulkPackageSizes = [
    { id: 1, name: 'Small', info: 'up to 2kg'},
    { id: 2, name: 'Medium', info: '2kg to 10kg'},
    { id: 3, name: 'Large', info: '10kg to 30kg'},
    { id: 4, name: 'Extra Large', info: 'over 30kg'},
    { id: 5, name: 'Variable Weight'},
  ];

export const standardPackageSize = [
    { id: 1, name: 'Light Weight', info: '1-250 kg'},
    { id: 2, name: 'Regular', info: '251-500 kg'},
    { id: 3, name: 'Bulky', info: '501-750 kg'},
    { id: 4, name: 'Super Heavy', info : '751-1000 kg'},
    { id: 5, name: 'Heavy Weight', info: '1001 kg and above'},
    // { id: 5, name: 'Variable Weight', info: ' '},
]

export const dropLocationOrderStatus = [
    {id : 1, title : 'Delivered', isShow : 1},
    {id : 2, title : 'Cancelled', isShow : 1},
    {id : 3, title : 'Returned', isShow : 1}
]


export const chagneStatusReasons = [
    {id : 1, title:'Incorrect Address'},
    {id : 2, title:'Failed Payment on Delivery'},
    {id : 3, title:'Refusal of package delivery by customer'},
    {id : 4, title:'Damaged Package'},
    {id : 5, title:'Customer Not Available'},
    {id : 6, title:'Business Closed'},
    {id : 7, title:'Expired Hold Time at Pickup Point'},
    {id : 8, title:'Expired Hold Time at Delivery Point'},
    {id : 9, title:'Cancellation by Sender/Merchant'},
    {id : 10, title:'Insufficient Delivery Details'}, 
    {id : 0, title:'Other'},
]
