<div class="dialog-content-wrapper">
    <mat-toolbar class="primary-700 m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{_data.heading}}</span>
            <button mat-icon-button  [mat-dialog-close]="false" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div mat-dialog-content>

    <form [formGroup]="this.form">
        <div style="display: flex; flex-direction:column;gap:8px">
            
            <mat-form-field appearance="outline" floatLabel="always" class="pl-4">
                 <mat-label [style.color]="'#fff'">Question</mat-label>
                 <input style="caret-color: #ddd;" type="text" formControlName="question" matInput placeholder="Add your question here..." [style.color]="'#fff'">
                 <mat-error>question is required</mat-error>
             </mat-form-field> 
      
             <mat-form-field appearance="outline" floatLabel="always" class="pl-4">
                 <mat-label [style.color]="'#fff'">Answer</mat-label>
                 <textarea matInput formControlName="answer" placeholder="Add your answer here..." rows="4" [style.color]="'#fff'"></textarea> 
                 <mat-error>answer is required</mat-error>
             </mat-form-field> 

        </div>
    </form>
</div>
<div mat-dialog-actions style="justify-content: center;">
    <button mat-raised-button class="mat-accent mr-16" [disabled]="this.form.invalid" (click)="dialogRef.close(this.form.value)">
        {{_data.btnText}}
    </button>
</div>