import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './main/pages/auth.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { encrypt, shift } from '@fuse/utils/enums';

@Injectable({
  providedIn: 'root'
})
export class PermissionguardGuard implements CanActivate {

  shift = shift;



  constructor( private _router:Router, private _ActivatedRoute: ActivatedRoute, private _authService: AuthService, private _fuseNavigationService : FuseNavigationService){

  }

  checkPermission(state){

    let navigation = this._fuseNavigationService.getCurrentNavigation();
    let flatNanvigation = this._fuseNavigationService.getFlatNavigation(navigation[0].children);
    let rolePermission = JSON.parse(localStorage.getItem('menuPermission'))

    console.log("flatnavigation",state.url, flatNanvigation);

    const result = flatNanvigation.filter(navItem =>  String(navItem.url).includes(String(state.url).slice(1, String(state.url).length)));
    console.log("currentNav", result, rolePermission[result[0]?.id]);

    
    if(result?.length){

      const currentNavMatchedId = result.filter((item) => rolePermission.hasOwnProperty(item.id))
      console.log("currentNavMatchedId", currentNavMatchedId, rolePermission[result[0]?.id]); 
      
      
      
      this._authService.actionPermissionUpdate.next(rolePermission[result[0]?.id]);
      
      if(rolePermission[result[0]?.id] !== "Hide"){ 
        const keyname = encrypt('paccess', shift);
        const value = encrypt(String(rolePermission[result[0]?.id]), shift);
        console.log("==--===>keyname", keyname, value);
        
        localStorage.setItem(keyname, value)
        return true;
      }else{
        const keyname = encrypt('paccess', shift);
        localStorage.setItem(keyname, '');
        this._authService.actionPermissionUpdate.next('');
        console.warn('Access denied!');
        this._router.navigate(['/errors/error-404'])
        return false;
      }

    }else{
      return true;
    }
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log("canActivate called");
      
      return this.checkPermission(state)
  }
  
}
