import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-update-driver-address-dialog',
  templateUrl: './update-driver-address-dialog.component.html',
  styleUrls: ['./update-driver-address-dialog.component.scss'],
  animations   : fuseAnimations,
})
export class UpdateDriverAddressDialogComponent implements OnInit, AfterViewInit {

  latitude: number;
  longitude: number;
  zoom: number = 8;
  private geoCoder;
  locality: any; 
  region: any;
  country:string;
  countryRestriction = {
    latLngBounds: {},
    strictBounds: false
  };

  ghanaBounds = {
    east: 1.0601216976, // right
    north: 11.0994559693, // TOP
    south: 4.71046214438, // bottom
    west: -3.24437008301 // left
  };

  nigeriaBounds = {
    east: 14.5771777686, // right
    north: 13.8659239771, // TOP
    south: 4.24059418377, // bottom
    west: 2.69170169436 // left
  };


  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;
  address: any;

  
  constructor(
    public matDialogRef: MatDialogRef<UpdateDriverAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _matsnackbar : MatSnackBar,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.country = _data.country;
    this.latitude = Number(_data.lat);
    this.longitude = Number(_data.long);
   }
 
  ngOnInit(): void {
    this.loadPlaceAutoComplete(); 
  }

  ngAfterViewInit():void{
    this.searchElementRef.nativeElement.value = this._data.address;
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  loadPlaceAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      
      this.geoCoder = new google.maps.Geocoder;
 
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        // types: ['(cities)'],
        componentRestrictions: { country: this.country == 'Ghana' ? 'Gh' : 'Ng' }
      });
      // console.log("autocomplete", autocomplete);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom 
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  markerDragEnd($event: any) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.searchElementRef.nativeElement.value = this.address;
          this.handleAddressChange(results[0]);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  handleAddressChange(address) {
    console.log("address[0]", address);
    const addressParts = {
      city: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_2')
          return item.long_name
      })[0],

      state: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_1')
          return item.long_name
      })[0],

      country: address.address_components.filter(item => {
        if (item.types[0] == "country")
          return item.long_name
      })[0],

      zipcode: address.address_components.filter(item => {
        if (item.types[0] == "postal_code")
          return item.long_name
      })[0],

      locality: address.address_components.filter(item => {
        if (item.types[0] == "locality")
          return item.long_name
      })[0],

      subLocality: address.address_components.filter(item => {
        if (item.types[2] == 'sublocality_level_1')
          return item.long_name
      })[0],

    }

    console.log("addressParts", addressParts);
    console.log("addressParts['country'].long_name", addressParts['country'].long_name);
    console.log("this.country", this.country);

    if(this.country)
    if(addressParts['country'].long_name != this.country){
      this.countryUnmatched();
    }
    
    this.locality = (addressParts?.locality?.long_name || addressParts?.subLocality?.long_name) || '';
    this.region = addressParts?.state?.long_name || '';
    console.log("addressParts", addressParts);
  }


  
  countryUnmatched(){
    this._matsnackbar.open(`Your selected location is not in ${this.country}. Please pick a place within ${this.country}.`, 'Okay', {duration : 2000})
    this.searchElementRef.nativeElement.value = '';
  }

  changeLocation() { 
    this.matDialogRef.close({lat: this.latitude,long: this.longitude,address: this.searchElementRef.nativeElement.value,locality: this.locality,region :  this.country})
  }
}
