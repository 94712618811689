import { Component, Inject, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector     : 'message-viewer-dialog',
    templateUrl  : './message-viewer-dialog.component.html',
    styleUrls    : ['./message-viewer-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MessageViewerDialogComponent implements OnInit,OnDestroy
{

  public messageBody:any = ''

    /**
     * Constructor
     *
     * @param {MatDialogRef<MessageViewerDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<MessageViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,

    )
    {


    }

    /**
     * On Init
     */

    ngOnInit(){


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

}
