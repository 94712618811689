export class BulkDeliveryConfig{

    additionalItemGh: number;
    additionalRateGh: number;
    basePriceGh: number;
    discountGh:number;
    distanceRateGh:number;

    // basePriceNg: number;
    // additionalRateNg: number;
    // distanceRateNg:number;
    // additionalItemNg: number;
    discountNg:number;
    damageCoverPercentageGh: number;
    conveniencePercentageGh: number;
    conveniencePercentageOfflineGh:Number;

    damageCoverPercentageNg:number;
    conveniencePercentageNg:number;
    conveniencePercentageOfflineNg:Number;
    // loadingFeePercentageGh:number;
    // loadingFeePercentageNg:number;

    /**
     * Constructor
     *
     * @param bulkDeliveryConfig
     */
    constructor(bulkDConfig?){
        bulkDConfig = bulkDConfig || {};
        this.additionalItemGh = this.getData(bulkDConfig, 'additionalItemGh', 'Ghana') || '';
        this.additionalRateGh = this.getData(bulkDConfig, 'additionalRateGh', 'Ghana') || '';
        this.basePriceGh = this.getData(bulkDConfig, 'basePriceGh', 'Ghana') || '';
        this.discountGh = this.getData(bulkDConfig, 'discountGh', 'Ghana') || '';
        this.distanceRateGh = this.getData(bulkDConfig, 'distanceRateGh', 'Ghana') || '';
        this.damageCoverPercentageGh = this.getData(bulkDConfig, 'damageCoverPercentageGh', 'Ghana' || '')
        this.conveniencePercentageGh = this.getData(bulkDConfig, 'conveniencePercentageGh', 'Ghana' || '')
        this.conveniencePercentageOfflineGh = this.getData(bulkDConfig, 'conveniencePercentageOfflineGh', 'Ghana' || '')
        // this.loadingFeePercentageGh = this.getData(bulkDConfig, 'loadingFeePercentageGh', 'Ghana' || '')

        // this.basePriceNg = this.getData(bulkDConfig, 'basePriceNg', 'Nigeria') || '';
        // this.additionalRateNg = this.getData(bulkDConfig, 'additionalRateNg', 'Nigeria') || '';
        // this.distanceRateNg = this.getData(bulkDConfig, 'distanceRateNg', 'Nigeria') || ''; 
        // this.additionalItemNg = this.getData(bulkDConfig, 'additionalItemNg', 'Nigeria') || '';
        this.discountNg = this.getData(bulkDConfig, 'discountNg', 'Nigeria') || '';
        this.damageCoverPercentageNg = this.getData(bulkDConfig, 'damageCoverPercentageNg', 'Nigeria' || '');
        this.conveniencePercentageNg = this.getData(bulkDConfig, 'conveniencePercentageNg', 'Nigeria' || '');
        this.conveniencePercentageOfflineNg = this.getData(bulkDConfig, 'conveniencePercentageOfflineNg', 'Nigeria' || '')
        // this.loadingFeePercentageNg = this.getData(bulkDConfig, 'loadingFeePercentageNg', 'Nigeria' || '')

 
    }
 
    getData(data,type,country){
        data = data.filter(e => e.country == country);
        data = data[0];
        let deliveryData;
        if(country ==='Ghana') { 
            if(type =='additionalItemGh'){
                deliveryData =  data.additionalItem;
            }
            else if(type =='additionalRateGh') {
                deliveryData =  data.additionalRate;
            }
            else if(type=='basePriceGh') {
                deliveryData =  data.basePrice;
            }
            else if(type =='discountGh') {
                deliveryData =  data.discount;
            }
            else if(type =='distanceRateGh') {
                deliveryData =  data.distanceRate;
            } 
            else if(type == 'damageCoverPercentageGh'){
                deliveryData = data.damageCoverPercentage;
            }
            else if(type == 'conveniencePercentageGh'){
                deliveryData = data.conveniencePercentage;
            }
            else if(type == 'conveniencePercentageOfflineGh'){
                deliveryData = data.conveniencePercentageOffline;
            }
            else if(type == 'loadingFeePercentageGh'){
                deliveryData = data.loadingFeePercentage;
            }
        }

        else if(country==='Nigeria') {
            
            
            if(type=='discountNg') {
                deliveryData =  data.discount;
            }
            // else if(type=='basePriceNg') {
            //     deliveryData =  data.basePrice;
            // }
            // else if(type=='additionalRateNg') {
            //     deliveryData =  data.additionalRate;
            // }
            // else if(type=='distanceRateNg') {
            //     deliveryData =  data.distanceRate;
            // }
            // else if(type=='additionalItemNg'){
            //     deliveryData =  data.additionalItem;
            // }
            else if(type == 'damageCoverPercentageNg'){
                deliveryData = data.damageCoverPercentage;
            }
            else if(type == 'conveniencePercentageNg'){
                deliveryData = data.conveniencePercentage;
            }
            else if(type == 'conveniencePercentageOfflineNg'){
                deliveryData = data.conveniencePercentageOffline;
            }
            else if(type == 'loadingFeePercentageNg'){
                deliveryData = data.loadingFeePercentage;
            }
        }
        return deliveryData;
    }
    
}