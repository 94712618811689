<div class="dialog-content-wrapper">
    <mat-toolbar class="primary-700 m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Scheduled Message</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0 primary-600" fusePerfectScrollbar>

        {{messageBody}}

    </div>

    <!-- <div mat-dialog-actions class="m-0 p-16 primary-600" fxLayout="row" fxLayoutAlign="flex-end center">
        <div>
            <button mat-raised-button
                    color="accent"
                    (click)="matDialogRef.close(['cancel',orderCancelForm])"
                    class="save-button"
                    [disabled]="orderCancelForm.invalid"
                    aria-label="CANCEL">
                Cancel order now
            </button>
        </div>
    </div> -->

</div>
