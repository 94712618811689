import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router } from '@angular/router';

@Component({
    selector     : 'error-404',
    templateUrl  : './error-404.component.html',
    styleUrls    : ['./error-404.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error404Component
{
    linkName: string;
    link: string;
    
    /**
     * Constructor
     * @param {FuseConfigService} _fuseConfigService 
     */
    constructor( 
        private _fuseConfigService: FuseConfigService,
        private _router: Router
    )
    {
        // Generate link and text 
        this.goBack();


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }




    goBack(){
            if(!localStorage.getItem('userToken')){
                this.linkName = 'login';
                this.link = '/login'
            }
            else{

                this.linkName = 'dashboard';
                this.link = '/dashboard'
                
            }
    }


}
