import { NgModule } from '@angular/core';

import {MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from '@angular/material/button'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatIconModule} from '@angular/material/icon'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageViewerDialogComponent } from './message-viewer-dialog.component';

@NgModule({
    declarations: [
        MessageViewerDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,

    ],
    entryComponents: [
        MessageViewerDialogComponent
    ],
})
export class MessageViewerDialogModule
{
}
