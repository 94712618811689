<div class="dialog-content-wrapper">
    <mat-toolbar class="primary-700 m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Order Cancellation Reason</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0 primary-600" fusePerfectScrollbar>


        <form name="orderCancelForm" [formGroup]="orderCancelForm" class="compose-form" fxLayout="column" fxFlex>

            <div class="show-hide-extra-fields mb-12" fxLayout="row" fxLayoutAlign="center center"
            fxLayoutAlign.gt-sm="space-between center" >
                <span class="status text-truncate h6 p-4 indigo-400-bg"  >Please share reason for cancelling this order</span>
            </div>

            <mat-form-field appearance="outline" color="accent" floatLabel="always" class="w-100-p" >
                <mat-label style="color: #039be5">Cancellation reason</mat-label>
                <textarea matInput matInput placeholder="Cancellation reason"
                          name="cancellationReason"
                          formControlName="cancellationReason"
                          rows="5" >
                </textarea>
             </mat-form-field> 
    

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16 primary-600" fxLayout="row" fxLayoutAlign="flex-end center">
        <div>
            <button mat-raised-button
                    color="accent"
                    (click)="matDialogRef.close(['cancel',orderCancelForm])"
                    class="save-button"
                    [disabled]="orderCancelForm.invalid"
                    aria-label="CANCEL">
                Cancel order now
            </button>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <!-- <button mat-icon-button (click)="matDialogRef.close(['delete',composeForm])"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button> -->
    </div>
</div>
