import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule } from '@angular/material/icon';
import {MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {NgxImageCompressService} from 'ngx-image-compress';
// import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';


import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { SubAdminModule } from './main/subadmin/subadmin.module';
import { StaffModule } from './main/staff/staff.module';
import { EcommerceModule } from './main/e-commerce/e-commerce.module';
import { CalendarModule } from './main/deals/calendar.module';
import { FeedbackModule } from './main/feedback/feedback.module';
import { ProfileSettingsModule } from './main/profile_settings/profile.module';
import { AnalyticsDashboardModule } from './main/dashboard/analytics.module';
// import { InboxModule } from './main/inbox/inbox.module';
import { AuthService } from './main/pages/auth.service';
import { AuthguardGuard } from './authguard.guard';
import { TransactionsModule } from './main/transactions/transactions.module';

import { UserIdleModule } from 'angular-user-idle';
import { EventsModule } from './main/events/event.module';

import { Error404Module } from './main/pages/errors/404/error-404.module';
import { CustomizerService } from './main/global_services/customizer.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InterRegionalDeliveryConfigurationModule } from './main/inter-regional-delivery-configuration/inter-regional-delivery-configuration.module';
import { BulkDeliveryConfigModule } from './main/bulk-delivery-config/bulk-delivery-config.module';
import { PermissionguardGuard } from './permissionguard.guard';





const appRoutes: Routes = [
    {
        path        : 'auth',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },
    {
        path        : 'merchants',
        loadChildren: () => import('./main/merchants/merchants.module').then(m => m.MerchantsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]

    },

    {
        path        : 'pdas-users',
        loadChildren: () => import('./main/pdas-users/pdas-users.module').then(m => m.PdasUsersModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'business-partners',
        loadChildren: () => import('./main/business-partners/business-partners.module').then(m => m.BusinessPartnersModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'customers',
        loadChildren: () => import('./main/customers/customers.module').then(m => m.CustomersModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]

    },

    {
        path        : 'delivery',
        loadChildren: () => import('./main/delivery/drivers.module').then(m => m.DriversModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]

    },
    {
        path        : 'category',
        loadChildren: () => import('./main/categories/categories.module').then(m => m.CategoriesModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]

    },
    {
        path        : 'list',
        loadChildren: () => import('./main/e-commerce/e-commerce.module').then(m => m.EcommerceModule),
        canLoad:[AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'stats',
        loadChildren: () => import('./main/all-orders/stats.module').then(m => m.StatsModule),
        canLoad:[AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'deals',
        loadChildren: () => import('./main/deals/calendar.module').then(m => m.CalendarModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'promotions-revenue',
        loadChildren: () => import('./main/promotions-revenue/promotions-revenue.module').then(m => m.PromotionsRevenueModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'events',
        loadChildren: () => import('./main/events/event.module').then(m => m.EventsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'transactions',
        loadChildren: () => import('./main/transactions/transactions.module').then(m => m.TransactionsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'settings',
        loadChildren: () => import('./main/profile_settings/profile.module').then(m => m.ProfileSettingsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'notify-user',
        loadChildren: () => import('./main/notify-user/notify-user.module').then(m => m.NotifyUserModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'dashboard',
        loadChildren: () => import('./main/dashboard/analytics.module').then(m => m.AnalyticsDashboardModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'pdas-convenience-fee',
        loadChildren: () => import('./main/pdas-convenience-fee/pdas-convenience-fee.module').then(m => m.PdasConvenienceFeeModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'subadmins',
        loadChildren: () => import('./main/subadmin/subadmin.module').then(m => m.SubAdminModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'phlenu-staff',
        loadChildren: () => import('./main/phlenu-staff/phlenu-staff.module').then(m => m.PhlenuStaffModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'staff',
        loadChildren: () => import('./main/staff/staff.module').then(m => m.StaffModule),
        canLoad: [AuthguardGuard],  
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'feedback',
        loadChildren: () => import('./main/feedback/feedback.module').then(m => m.FeedbackModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'upgrade-version',
        loadChildren: () => import('./main/upgrade-version/upgrade-version.module').then(m => m.UpgradeVersionModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'delivery-config',
        loadChildren: () => import('./main/delivery-config/delivery-config.module').then(m => m.DeliveryConfigModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'bulk-delivery',
        loadChildren: () => import('./main/bulk-delivery-config/bulk-delivery-config.module').then(m => m.BulkDeliveryConfigModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'inter-regional',
        loadChildren: () => import('./main/inter-regional-delivery-configuration/inter-regional-delivery-configuration.module').then(m => m.InterRegionalDeliveryConfigurationModule),
        canLoad: [AuthguardGuard],  
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'business-delivery',
        loadChildren: () => import('./main/pharmacy-delivery-config/pharmacy-delivery-config.module').then(m => m.PharmacyDeliveryConfigModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'affiliates-tiers',
        loadChildren: () => import('./main/affiliates-tiers-manage/affiliates-tiers-manage.module').then(m => m.AffiliatesTiersManageModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard] 
    },
    {
        path        : 'event-ticket-requests',
        loadChildren: () => import('./main/event-ticket-request/event-ticket.module').then(m => m.EventTicketModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'country',
        loadChildren: () => import('./main/countries/countries.module').then(m => m.CountriesModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'deal-stats',
        loadChildren: () => import('./main/all-deals/all-deals.module').then(m => m.AllDealsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'event-stats',
        loadChildren: () => import('./main/all-events/all-events.module').then(m => m.AllEventsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'product-stats',
        loadChildren: () => import('./main/all-products/all-products.module').then(m => m.AllProductsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'service-stats',
        loadChildren: () => import('./main/all-services/all-services.module').then(m => m.AllServicesModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard] 
    },
    {
        path        : 'addons',
        loadChildren: () => import('./main/purchases/purchases.module').then(m => m.PurchasesModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'commissions',
        loadChildren: () => import('./main/commission/commission.module').then(m => m.CommissionModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]  
    },
    {
        path        : 'withdrawal-requests',
        loadChildren: () => import('./main/withdrawal-requests/withdrawal-requests.module').then(m => m.WithdrawalRequestModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'coupons',
        loadChildren: () => import('./main/coupons/coupons.module').then(m => m.CouponsModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard] 
    },
    {
        path        : 'promocode',
        loadChildren: () => import('./main/promocode/promocode.module').then(m => m.PromocodeModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]  
    },
    {
        path        : 'affiliates',
        loadChildren: () => import('./main/affiliates/affiliates.module').then(m => m.AffiliatesModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'devices-brands',
        loadChildren: () => import('./main/devices-groups-brands-pricing/devices-groups-brands-pricing.module').then(m => m.DevicesGroupsBrandsPricingModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard] 
    },


    {
        path        : 'earning',
        loadChildren: () => import('./main/earning/earning.module').then(m => m.EarningModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    // {
    //     path        : 'earning/referral-earning-pdas',
    //     loadChildren: () => import('./main/earning/referral-earning-pdas/referral-earning-pdas.module').then(m => m.ReferralEarningPdasModule),
    //     canLoad: [AuthguardGuard]
    // },

    {
        path        : 'cart',
        loadChildren: () => import('./main/customer-cart/customer-cart.module').then(m => m.CustomerCartModule),
        canLoad:[AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'customer-kyc',
        loadChildren: () => import('./main/customer-kyc/kyc.module').then(m => m.KycModule),
        canLoad:[AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'wishlist',
        loadChildren: () => import('./main/customer-wishlist/customer-wishlist.module').then(m => m.CustomerWishlistModule),
        canLoad:[AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    {
        path        : 'home-content',
        loadChildren: () => import('./main/home-content/home-content.module').then(m => m.HomeContentModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'pdas-discount-timeslots',
        loadChildren: () => import('./main/pdas-discount/pdas-discount.module').then(m => m.PdasDiscountModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    
    {
        path        : 'recharge-list',
        loadChildren: () => import('./main/recharge-list/recharge-list.module').then(m => m.RechargeListModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'risky-users',
        loadChildren: () => import('./main/abuse-users/abuse-user.module').then(m => m.AbuseUserModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'email-logs',
        loadChildren: () => import('./main/email-validation-request-log/email-log.module').then(m => m.EmailLogModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'notify-customers',
        loadChildren: () => import('./main/notify-customers/notify-customers.module').then(m => m.NotifyCustomersModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'menu',
        loadChildren: () => import('./main/manage-menu/manage-menu.module').then(m => m.ManageMenuModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },

    {
        path        : 'manage-promos',
        loadChildren: () => import('./main/manage-promo/promo.module').then(m => m.PromoModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    }, 
    {
        path        : 'vehicle-inspection',
        loadChildren: () => import('./main/vehicle-inspection/vehicle-inspection.module').then(m => m.VehicleInspectionModule),
        canLoad: [AuthguardGuard],
        canActivate:[PermissionguardGuard]
    },
    // {
    //     path        : 'add-product',
    //     loadChildren: './main/add-product/add-product.module#AddProductModule',
    //     canLoad: [AuthguardGuard],
    //     // canActivateChild: [AuthguardGuard]
    // },

    // {
    //     path        : 'settings',
    //     canActivate:[AuthguardGuard],
    //     loadChildren: './main/profile_settings/profile.module#ProfileSettingsModule'
    // },
    // {
    //     path        : 'inbox',
    //     loadChildren: './main/inbox/inbox.module#InboxModule'
    // },
    

    // Main Routes
    {path: '',redirectTo: 'auth',pathMatch: 'full',},
    {path: 'login',redirectTo: 'auth/login',pathMatch: 'full'},
    {path: 'forgot-password',redirectTo: 'auth/forgot-password',pathMatch: 'full'},
    {path: 'lock',redirectTo: 'auth/lock',pathMatch: 'full'},
    {path: 'reset-password',redirectTo: 'auth/reset-password',pathMatch: 'full'},
   

    // Navbar Routes
    // { path: 'dashboard', canActivate:[AuthguardGuard], redirectTo: 'dashboard' },
    // { path: 'sample', canActivate:[AuthguardGuard], redirectTo: 'sample' },
    // { path: 'staff', canActivate:[AuthguardGuard], redirectTo: 'staff' },
    { path: 'merchants', redirectTo: 'merchants' },

    { path: 'products', redirectTo: 'list/products' },
    { path: 'products/:id', redirectTo: 'list/products/:id' },
    { path: 'products/:id/:handle', redirectTo: 'list/products/:id/:handle' },
    { path: 'orders/:id', redirectTo: 'list/orders/:id' },
    { path: 'orders/:merchantId/:id', redirectTo: 'list/orders/:merchantId/:id' },

     { path: 'deals', redirectTo: 'deals' },
    // { path: 'deals/:id', redirectTo: 'list/deals/:id' },
    // { path: 'deals/:id/:handle', redirectTo: 'list/deals/:id/:handle' },


    { path: 'notify-user/merchants', redirectTo: 'notify-user/merchants' },
    { path: 'notify-user/customer', redirectTo: 'notify-user/customer' },

    { path: 'addons/purchased-employee-plan', redirectTo: 'addons/purchased-employee-plan' },
    { path: 'addons/purchased-boosters', redirectTo: 'addons/purchased-boosters' },
   
    { path: 'upgrade-version', redirectTo: 'upgrade-version' },
    { path: 'country/all', redirectTo: 'country' },
    { path: 'subadmins/roles', redirectTo: 'subadmins' },
    { path: 'subadmins/roles/:id', redirectTo: 'subadmins' },


    { path: 'affiliate-earning', redirectTo: 'earning/affiliate-earning' },
    { path: 'referral-earning', redirectTo: 'earning/referral-earning' },
    { path: 'referral-earning-pdas', redirectTo: 'earning/referral-earning-pdas' },
    { path: 'phelnu-cash', redirectTo: 'earning/phelnu-cash' },



    // { path: 'cart', redirectTo: 'user-cart/cart' },



    // { path: 'events', canActivate:[AuthguardGuard],  redirectTo: 'events' },
    // { path: 'settings', canActivate:[AuthguardGuard], redirectTo: 'settings' },
    // { path: 'transactions', canActivate:[AuthguardGuard], redirectTo: 'transactions' },
    // { path: 'inbox', canActivate:[AuthguardGuard], redirectTo: 'inbox' },
    // { path: 'chat',   canActivate:[AuthguardGuard],redirectTo: 'chat' },
    

    // Navbar Typos Redirections
    {path : 'Dashboard',redirectTo: 'dashboard'},
    {path : 'DASHBOARD',redirectTo: 'dashboard'},


    // Default Error Routes

    {path: '**', redirectTo: 'errors/error-404',pathMatch: 'full'}


];

@NgModule({
    declarations: [
        AppComponent,


    ],
    imports     : [
        // CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        // , {enableTracing: true}

        TranslateModule.forRoot(),
        
      

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 700,
            passThruUnknownUrl: true
        }),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        UserIdleModule.forRoot({idle: 600, timeout: 60, ping: 120}),

        // App modules
        LayoutModule,
        BulkDeliveryConfigModule,
        SampleModule,
        InterRegionalDeliveryConfigurationModule,
        // AnalyticsDashboardModule,
        // StaffModule,
        // EcommerceModule,
        // CalendarModule,
        // EventsModule,
        // FeedbackModule,
        // ProfileSettingsModule,
        // TransactionsModule,
        // InboxModule,
        // ChatModule,
        // GooglePlaceModule,
        // App error Module
        Error404Module,


        
        
    ],
    bootstrap   : [
        AppComponent
    ],
    providers   : [
        AuthService, AuthguardGuard, CustomizerService , NgxImageCompressService
    ]
})
export class AppModule
{
}
