<h1 matDialogTitle>{{confirmHeading}}</h1>
<div mat-dialog-content>{{confirmMessage}}</div>
<div mat-dialog-actions class="pt-24">
    <button mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(true)" [ngClass]="confirmBottonText == 'Continue' ? 'continue-button':''">{{confirmBottonText}}</button>
    <button *ngIf="showCancelButton" mat-button (click)="dialogRef.close(false)" color="warning">Cancel</button>
</div>



<!--<div *ngIf="isTypeMsg">-->
<!--    <div mat-dialog-content>{{confirmMessage}}</div>-->
<!--    <div mat-dialog-actions class="pt-24" style="justify-content: center;">-->
<!--        <button mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(true)">OK</button>-->
<!--         &lt;!&ndash;        <button mat-button (click)="dialogRef.close(false)">Cancel</button>&ndash;&gt;-->
<!--    </div>-->
<!--</div>-->
