<div matDialogTitle>
    <span>Update Address</span>
    <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div fxLayout="column" fxFlex>
        <div class="show-hide-extra-fields mb-12" fxLayout="row" fxLayoutAlign="center center"
            fxLayoutAlign.gt-sm="space-between center">
            <span class="status h6 p-4 indigo-400-bg">
                Current Location : {{_data.address}}</span>
        </div>

        <div fxLayout="row" fxFlex>
            <div fxFlex="1 0 auto" fxLayout="column" fxFlex="100">
                <mat-form-field appearance="outline" color="accent" floatLabel="always" fxFlex="100">
                    <mat-label style="color: #039be5;"> Enter a location </mat-label>
                    <input type="text" matInput style="color: whitesmoke;" (keydown.enter)="$event.preventDefault()"
                        placeholder="Search Location" autocorrect="off" autocapitalize="off" spellcheck="off"
                        type="text" #search>
                </mat-form-field>
            </div>


        </div>

        <div fxLayout="row" fxFlex>
            <div fxFlex="1 0 auto" class="map-wrapper" fxLayout="column" fxFlex="100"> 
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)"></agm-marker>
                </agm-map>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="changeLocation()"
        [disabled]="(!this.latitude && !this.longitude) || this.searchElementRef.nativeElement.value == ''" class="save-button" aria-label="SEARCH">
        Change
    </button>
</mat-dialog-actions>