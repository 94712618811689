import * as moment from 'moment';
export class FuseUtils
{
    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @returns {any}
     */
    public static filterArrayByString(mainArr, searchText): any
    {
        if ( searchText === '' )
        {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns {boolean}
     */
    public static searchInObj(itemObj, searchText): boolean
    {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            else if ( Array.isArray(value) )
            {
                if ( this.searchInArray(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns {boolean}
     */
    public static searchInArray(arr, searchText): boolean
    {
        for ( const value of arr )
        {
            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any
    {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string
    {
        function S4(): string
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                       .toString(16)
                       .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void
    {
        if ( array.indexOf(item) === -1 )
        {
            array.push(item);
        }
        else
        {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string
    {
        return text.toString().toLowerCase()
                   .replace(/\s+/g, '-')           // Replace spaces with -
                   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                   .replace(/^-+/, '')             // Trim - from start of text
                   .replace(/-+$/, '');            // Trim - from end of text
    }

  
    /**
     * Generates UTC based on specified date and time 
     *
     * @param specifiedDate
     * @param specifiedTime
     */
    public static generateUTC(specifiedDate, specifiedTime): string
    {

        console.log('specifiedDate :>> ', specifiedDate);
        console.log('specifiedtime :>> ', specifiedTime);

        specifiedDate._i.month = (specifiedDate._i.month + 1);
        let utcDate = specifiedDate;
        let month = (specifiedDate._i.month < 10) ? "0" + specifiedDate._i.month : specifiedDate._i.month;
        let date = (specifiedDate._i.date < 10) ? "0" + specifiedDate._i.date : specifiedDate._i.date;
        specifiedDate = `${specifiedDate._i.year}-${month}-${date}`;

        console.log(`OUTPUT: specifiedDate after completion ${specifiedDate} == ${specifiedTime}`);

        console.log(utcDate);




        let newMonth: string | number = new Date(utcDate._d).getMonth() + 1;
        let getDay: string | number =   new Date(utcDate._d).getDate();
        newMonth  = (newMonth < 10) ? '0' + newMonth : newMonth;
        getDay =  (getDay < 10) ?  '0' + getDay : getDay;

        utcDate = new Date(utcDate._d).getFullYear() +'-'+ newMonth +'-'+ getDay;






        let utc =  moment(`${utcDate} ${specifiedTime}`).utc().format('YYYY-MM-DD HH:mm:ss');
        return  utc;
        // const NewDate = new Date(`${utcDate} ${specifiedTime}`).toUTCString();
        // console.log(NewDate);

        //  newMonth = new Date(NewDate).getMonth() + 1;
        //  getDay =  new Date(NewDate).getDate();
        //
        //  newMonth  = (newMonth < 10) ? '0' + newMonth : newMonth;
        //  getDay =  (getDay < 10) ?  '0' + getDay : getDay;
        //
        //  utcDate = new Date(NewDate).getFullYear() +'-'+ newMonth +'-'+ getDay;
        //
        //  console.log('newDate',NewDate);
        // console.log('newDate',utcDate);
       // const NewDate1 =  NewDate.split(' ');
         // let NewDate = new Date(specifiedDate._d).toUTCString();
         //  let utcDate = specifiedDate;
         // let NewDate1 =  NewDate.split(' ');
         //  console.log(utcDate._d);
         // let day =   new Date(utcDate._d).getDay();
         // let month1 =   new Date(utcDate._d).getMonth()+1;
         // let year =   new Date(utcDate._d).getFullYear();
         //  utcDate = year+'-'+month1+'-'+day;
         //   console.log('specifiedDate',utcDate);
         //   console.log('NewDate1',NewDate1);
         //
         // return  utcDate+' '+NewDate1[4];




        // let newMonth = new Date(utcDate._d).getMonth()+ 1;
        // let getDay =   new Date(utcDate._d).getDate();
        // newMonth  = (newMonth < 10) ?"0"+ newMonth : newMonth;
        // getDay =  (getDay < 10) ?"0"+getDay :getDay;
        // var dateStr =
        //     (new Date(date1).getFullYear()) + "-" +
        //     newMonth + "-" + getDay + " " +
        //     ("00" + new Date(date1).getHours()).slice(-2) + ":" +
        //     ("00" + new Date(date1).getMinutes()).slice(-2) + ":" +
        //     ("00" + new Date(date1).getSeconds()).slice(-2);

         //console.log('dateStr=>',dateStr);

       // return  specifiedDate+' '+NewDate1[4];
        // return dateStr;
    }


}
