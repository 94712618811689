import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ConvenienceFeeDialogComponent } from './components/convenience-fee-dialog/convenience-fee-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {  MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SelectDriverBulkOrderComponent } from './components/select-driver-bulk-order/select-driver-bulk-order.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ImageViewerDialogComponent } from './components/image-viewer-dialog/image-viewer-dialog.component';
import { StrictlyDialogComponent } from './components/strictly-dialog/strictly-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpdateDriverAddressDialogComponent } from './components/update-driver-address-dialog/update-driver-address-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { AddEditFaqDialogComponent } from './components/add-edit-faq-dialog/add-edit-faq-dialog.component';
import { CommonCropperComponent } from './components/common-cropper/common-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
    declarations : [ConvenienceFeeDialogComponent,CommonCropperComponent, AddEditFaqDialogComponent, UpdateDriverAddressDialogComponent, StrictlyDialogComponent, ImageViewerDialogComponent, SelectDriverBulkOrderComponent],
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        ImageCropperModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatSliderModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatToolbarModule,
        AgmCoreModule,
        // NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDosbgI6M3Iw9abM1cQTvFpOeJI3qpfIEs',
            libraries: ['places']

            // AIzaSyBOZI9gwD6_VidQtpzUv4B0IZXGLlUlz1I   PHlenu 's  map key original 
        }),
    ],
    exports  : [
        CommonModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule
    ]
})
export class FuseSharedModule
{
}
