<div class="strictly-dialog-wrapper">
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon class="mat-18">close</mat-icon>
        </button>
    </div>
    <img src="assets/images/strictly-bg.png" alt="">
    <h3>STRICTLY NO FRAUD</h3>
    <p>Unauthorized use of this Phlenu admin panel for fraudulent activities is illegal and will lead to severe consequences, including legal action and potential imprisonment. All activities are monitored. Compliance is mandatory!</p>
    <div>
        <mat-checkbox [formControl]="this.dontShowagain" color="accent">Don't show me again</mat-checkbox>
    </div>
    <button mat-button (click)="onSubmit()">OKAY</button>
</div>