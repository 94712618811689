<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.primaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/phlenu_logo.svg">
        <span class="logo-text secondary-text">
<!--            Phlenu Business-->
            Phlenu Admin

        </span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.primaryBackground">

        <div class="h3 username">{{businessName}}</div>  
        

        <div class="h5 email hint-text mt-8">{{userEmail}}</div>
        <div class="avatar-container"  [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img *ngIf="avatar !== 'null' " class="avatar" [src]="avatar" >
            <img *ngIf="avatar == 'null' " class="avatar" src="../../../../../../assets/images/avatars/profile.jpg" >
            <!-- <span matBadge="✔" matBadgeColor="accent" *ngIf="status == 'Verified'" matBadgeOverlap="true" matBadgeDescription="Verified">
            </span> -->
            <mat-icon class="s-18 status accent"  matTooltip="Verified" TooltipPosition="right"  *ngIf="status == 'Verified'"> check</mat-icon>
            <mat-icon class="s-18 status orange-800" matTooltip="Pending" TooltipPosition="right"   *ngIf="status == 'Pending'"> watch_later</mat-icon>
            <mat-icon class="s-18 status warn" matTooltip="Rejected"  TooltipPosition="right"  *ngIf="status == 'Rejected'"> remove</mat-icon>

        </div>
        

        <!-- TODO: Change badge to dynamic stuff -->

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
