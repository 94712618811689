<div id="error-404" fxLayout="column" >

    <!-- <div class="content primary-600" fxLayout="column" fxLayoutAlign="center center"> -->

        <div class="top-clouds mt-24" fxLayout="row"  fxLayoutAlign="center center">


            <div class="cloud-2 ml-80 mt-40" fxLayout="column" fxLayoutAlign="center flex-start" fxFlex="33" ><img src="../../../../../assets/images/backgrounds/cloud1.svg"> </div>
            <div class="cloud-3 mb-80" fxLayout="column" fxFlex="33"> <img src="../../../../../assets/images/backgrounds/cloud2.svg"></div>
            <div class="cloud-1 mr-80" fxLayout="column" fxFlex="33"> <img src="../../../../../assets/images/backgrounds/cloud3.svg"></div>

        </div>

        <div class="error m-24 pr-20 pl-20" fxLayout="row" fxLayoutAlign="center center" > 
                
            <div class="error-code-image ml-20" fxFlex="50" fxLayoutAlign="center center">
                        <img src="../../../../../assets/images/backgrounds/404_icon.svg">

                </div>

                <div class="error-code-message m-12 " fxLayout="column" fxFlex="50" fxLayoutAlign="center center">
                        
                        <span class="mat-display-3 mb-0" style="color: #339af5; font-weight: 900;">OOPS,</span>
                        <span class="mat-subheading-2" style="color: whitesmoke" >Something went wrong</span>
                        <a mat-flat-button color="primary" [routerLink]=link>Go back to {{linkName}}</a>
                        

                        <!-- <a mat-stroked-button color="accent" [routerLink]=link> </a> -->
                        
                    </div>
            
                  



        </div>



        <div class="bottom-clouds mt-20" fxLayout="row"  fxLayoutAlign="center center">
            <div class="cloud-6 mr-80" fxLayout="column wrap" fxFlex="33"> <img src="../../../../../assets/images/backgrounds/cloud3.svg"></div>
            <div class="cloud-5 mt-80" fxLayout="column wrap" fxFlex="33"> <img src="../../../../../assets/images/backgrounds/cloud2.svg"></div>
            <div class="cloud-4 ml-80" fxLayout="column wrap" fxLayoutAlign="center flex-start" fxFlex="33" ><img src="../../../../../assets/images/backgrounds/cloud1.svg"> </div>
        </div>


        
<!-- 
        <div class="error-code">404</div>

        <div class="message">Sorry but we could not find the page you are looking for</div> -->

        <!-- <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon s-24">search</mat-icon>
            <input placeholder="Search for something" fxFlex>
        </div> -->

        <!-- <a class="back-link" [routerLink]=link>
            Go back to {{linkName}}</a> -->

    <!-- </div> -->

</div>