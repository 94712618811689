<!-- <h1 matDialogTitle>PDAS Convenience Fee</h1>

<mat-dialog-content class="dialogBody">
    <form [formGroup]="this.form">
        <mat-form-field class="input-wrapper">
            <mat-label>Fee</mat-label>
            <input matInput formControlName="fee" pattern="^[1-9]+[0-9]*$">
            <mat-error class="err" *ngIf="this.form?.get('fee').hasError('pattern')">
                invalid data
            </mat-error>
            <mat-error *ngIf="this.form.get('fee').hasError('required')">Fee is required</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="onSubmit()" [disabled]="this.form.invalid">Submit</button>
</mat-dialog-actions> -->
<div class="dialog-content-wrapper">
    <mat-toolbar class="primary-700 m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">PDAS Convenience Fee</span>
            <button mat-icon-button  [mat-dialog-close]="false" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div mat-dialog-content class="p-24 m-0 primary-600" fusePerfectScrollbar>
    <div fxLayout="column" fxFlex>
        <form [formGroup]="this.form">
            <mat-form-field class="input-wrapper">
                <mat-label>Fee</mat-label>
                <input matInput formControlName="fee" type="number" [min]="1" [max]="100" pattern="^[1-9]+[0-9]*$">
                <mat-error class="err" *ngIf="this.form?.get('fee').hasError('pattern')">
                    invalid data
                </mat-error>
                <mat-error *ngIf="this.form.get('fee').hasError('required')">Fee is required</mat-error>
            </mat-form-field>
        </form>
    <div mat-dialog-actions class="m-0 p-16 primary-600" fxLayout="row" fxLayoutAlign="flex-end center">
        <div>
            <button mat-raised-button color="accent" class="save-button" aria-label="Submit" (click)="onSubmit()" [disabled]="this.form.invalid">
                Submit
            </button>
        </div>
    </div>
</div>
</div>
